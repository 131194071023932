console.group("inicializando o pack js das gems do app")
console.group("inicializando o jquery-mobile")
import './jquery.mobile.js';

console.groupEnd()

console.group("inicializando componentes da uxkit")


import InputChangeObserver from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/components/change_observer.js";
import AgentHelper from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/helpers/agent.js";
import Util from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/helpers/generic.js";
import SizingHelper from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/helpers/sizing.js";
import StringHelper from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/extensions/string.js";
import ArrayHelper from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/extensions/array.js";
import CookieHelper from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/helpers/cookie.js";

StringHelper.installMoneyParser();
StringHelper.installStringFormat();
ArrayHelper.installRemoveItem();

global.checkIfEmpty = Util.isEmpty;
global.resizeFullElements = SizingHelper.resizeFullElements;
global.goBackSafely = Util.goBackSafely;
global.goForwardSafely = Util.goForwardSafely;
global.InputChangeObserver = InputChangeObserver;
global.isMobile = AgentHelper;
global.createCookie = CookieHelper.create;
global.readCookie = CookieHelper.read;
global.eraseCookie = CookieHelper.remove;

import HolderComponent from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/components/holder.js";
import ContainerComponent from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/components/container.js";
import EmptyBuilder from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/components/builders/empty.js";
import SectionHelperContainerBuilder from "./helper_builder.js";
import HolderBuilder from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/components/builders/empty.js";
import AsyncViewProxy from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/components/proxies/base.js";
import CancellableAsyncViewProxy from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/components/proxies/cancellable.js";
import LoadingWidget from "/var/www/apps/mnr/engines/ux_kit19/app/frontend/util/js/components/loading.js";

global.LoadingWidget = LoadingWidget;
global.HolderComponent = HolderComponent;
global.ContainerComponent = ContainerComponent;
global.AsyncViewProxy = AsyncViewProxy;
global.CancellableAsyncViewProxy = CancellableAsyncViewProxy
global.EmptyContainerBuilder = EmptyBuilder;
global.SectionHelperContainerBuilder = SectionHelperContainerBuilder;
global.HolderBuilder = HolderBuilder;
console.groupEnd()



console.groupEnd()

